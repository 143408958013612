import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
// import { getLocalStorage, setLocalStorage } from '@/utils/local-storage'
export default {
  data: function data() {
    return {
      base_ws_url: "ws://device.gxlyb.com:8012/ws/device",
      ws_url: "",
      ws: null,
      ws_heart_i: null
    };
  },
  methods: {
    /**
     * 连接 websocket
     * @param func onopen要执行的函数，可以为空
     */
    ws_connect: function ws_connect(func) {
      var _this = this;

      if (this.ws !== null) {
        this.ws_close();
        this.ws = null;
      }

      this.ws = new WebSocket(this.ws_url); // 服务端主动推送消息时会触发这里的 onmessage

      this.ws.onmessage = function (e) {};

      this.ws.onopen = function () {
        // 开启心跳
        _this.ws_heart();

        if (typeof func == "function") {
          func();
        }
      };

      this.ws.onerror = function (e) {};

      this.ws.onclose = function (e) {};
    },
    setup_url: function setup_url(sn) {
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "000";
      this.ws_url = "".concat(this.base_ws_url, "/").concat(sn, "/").concat(id);
    },
    ws_bindMsg: function ws_bindMsg(func) {
      var _old$close = this.ws.onmessage;

      if (typeof func == "function") {
        this.ws.onmessage = function () {
          // 原本 onclose 里的代码先执行完毕
          _old$close.apply(this, arguments);

          func.apply(this, arguments);
        };
      }
    },
    ws_close: function ws_close() {
      if (this.ws !== null) {
        this.ws.close();
      }

      if (this.ws_heart_i) clearInterval(this.ws_heart_i);
    },
    ws_execute: function ws_execute(func) {
      var _arguments = arguments,
          _this2 = this;

      if (this.ws.readyState == 0) {
        // 正在链接中
        var _old$open = this.ws.onopen;

        this.ws.onopen = function () {
          // 原本 onopen 里的代码先执行完毕
          _old$open.apply(_this2, _arguments);

          if (typeof func == "function") {
            func();
          }
        };
      } else if (this.ws.readyState == 1) {
        // 已经链接并且可以通讯
        if (typeof func == "function") {
          func();
        }
      } else if (this.ws.readyState == 2) {
        // 连接正在关闭
        var _old$close = this.ws.onclose;

        this.ws.onclose = function () {
          // 原本 onclose 里的代码先执行完毕
          _old$close.apply(_this2, _arguments);

          _this2.ws_connect(func);
        };
      } else if (this.ws.readyState == 3) {
        // 连接已关闭或者没有链接成功
        this.ws_connect(func);
      }
    },
    ws_heart: function ws_heart() {
      var _this3 = this;

      if (this.ws_heart_i) clearInterval(this.ws_heart_i);
      this.ws_heart_i = setInterval(function () {
        var func = function func() {
          var data = {
            type: "ping"
          };

          _this3.ws.send(JSON.stringify(data));
        };

        _this3.ws_execute(func);
      }, 6000);
    }
  }
};