import "core-js/modules/es.function.name.js";
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "SalesData",
  data: function data() {
    return {
      height: 198,
      myChart: null,
      option: null,
      resizeHandle: null,
      chartWidth: 0,
      currentIndex: -1,
      sourceData: [{
        item: "开机",
        count: 0
      }, {
        item: "运行",
        count: 0
      }, {
        item: "故障",
        count: 0
      } // { item: "关闭", count: close },
      ]
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.init_chart(this.sourceData);
    this.resizeHandle = debounceTail(function () {
      return _this.resize();
    }, 299, this);
    window.addEventListener("resize", this.resizeHandle); // setInterval(() => {
    //   var dataLen = this.option.series[0].data.length;
    //   // 取消之前高亮的图形
    //   this.myChart.dispatchAction({
    //     type: "downplay",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    //   this.currentIndex = (this.currentIndex + 1) % dataLen;
    //   // 高亮当前图形
    //   this.myChart.dispatchAction({
    //     type: "highlight",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    //   // 显示 tooltip
    //   this.myChart.dispatchAction({
    //     type: "showTip",
    //     seriesIndex: 0,
    //     dataIndex: this.currentIndex,
    //   });
    // }, 1000);
  },
  destroyed: function destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    resize: function resize() {
      if (this.myChart) {
        this.myChart.resize();
        var newWidth = this.myChart.getWidth();

        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart: function init_chart(v) {
      var colors = ["#007aff", "#ffc619", "#ff494b"];
      if (!this.myChart) this.myChart = echarts.init(this.$refs.main_chart);
      this.option = {
        tooltip: {
          trigger: "item"
        },
        color: colors,
        // grid: {
        //   top: '1%',
        //   bottom: '50%',
        // },
        legend: {
          bottom: 0,
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 12
          },
          // formatter: function(data) {
          //   console.log(data);
          //   let res = data.marker + data.name + ':' + data.value;
          //   return res
          // },
          itemGap: 5,
          padding: [5, 5],
          data: [{
            icon: "circle",
            name: "开机"
          }, {
            icon: "circle",
            name: "运行"
          }, {
            icon: "circle",
            name: "故障"
          }]
        },
        series: [{
          type: "pie",
          radius: ["26%", "68%"],
          center: ["50%", "46%"],
          avoidLabelOverlap: false,
          top: 0,
          bottom: "10%",
          tooltip: {
            // formatter: '{a} :{b} {d}%'
            formatter: function formatter(data) {
              // console.log(data);
              var res = data.marker + data.name + ": " + data.value + "台 <br>占比 " + data.percent + "%";
              return res;
            }
          },
          label: {
            show: false,
            position: "center" // formatter: '{b}'
            // colors: '#fff',
            // formatter: function(data) {
            //   // console.log(data)
            //   let res = data.value;
            //   return res;
            // },
            // formatter: "{numb|{c}}",
            // rich: {
            //   numb: {
            //     color: '#fff',
            //     fontSize: 12,
            //     height: 12,
            //     lineHeight: 12,
            //     top: 6,
            //   },
            // },

          },
          // label: {
          //   show: true,
          //   color: "#fff",
          //   formatter: '{b} {d}%'
          // },
          labelLine: {
            show: false
          },
          itemStyle: {
            borderRadius: 2,
            borderColor: "#011448",
            borderWidth: 2
          },
          data: [{
            value: v[0].count,
            name: "开机"
          }, {
            value: v[1].count,
            name: "运行"
          }, {
            value: v[2].count,
            name: "故障"
          }]
        }, // {
        //   type: "pie",
        //   radius: ["72%", "74%"],
        //   center: ["50%", "46%"],
        //   avoidLabelOverlap: false,
        //   tooltip: {
        //     show: false,
        //   },
        //   top: 0,
        //   bottom: "10%",
        //   cursor: "auto",
        //   // emptyCircleStyle: {
        //   //   color: "#023374",
        //   // },
        //   label: {
        //     show: false,
        //     position: "center",
        //   },
        //   labelLine: {
        //     show: false,
        //   },
        //   data: [
        //     {
        //       value: 0,
        //       itemStyle: {
        //         color: "#023374",
        //       },
        //       emphasis: {
        //         scale: false,
        //       },
        //     },
        //   ],
        //   animation: false,
        // },
        {
          type: "pie",
          radius: ["14%", "16%"],
          center: ["50%", "46%"],
          avoidLabelOverlap: false,
          tooltip: {
            show: false
          },
          top: 0,
          bottom: "10%",
          cursor: "auto",
          emptyCircleStyle: {
            color: "#023374"
          },
          label: {
            show: false,
            position: "center"
          },
          labelLine: {
            show: false
          },
          data: [],
          animation: false
        }]
      }; // this.option.xAxis.data = v.map((i) => i.rank);
      // this.option.series[0].data = v.map((i) => i.num);

      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update: function update(v) {
      // const {stop,runing,error,close} = v
      if (v === undefined) return; // const { stop, runing, error, close } = v;

      var stop = v.stop,
          runing = v.runing,
          error = v.error;
      this.sourceData = [{
        item: "开机",
        count: runing
      }, // { item: "运行", count: stop + close },
      {
        item: "运行",
        count: stop
      }, {
        item: "故障",
        count: error
      } // { item: "关闭", count: close },
      ];
      this.init_chart(this.sourceData);
    }
  }
};