<template>
  <div class="monitor_table">
    <view-monitor
      v-if="visibleMonitor"
      :id="monitorId"
      :rec="recFlag"
      @cancel="() => (visibleMonitor = false)"
    />
    <a-table
      :dataSource="searchData"
      :columns="columns"
      :padding="[10, 0, 'auto', 0]"
      size="small"
      rowKey="key"
      :pagination="{
        position: 'bottom',
        style: { marginBottom: 0 },
        pageSize: pageSize,
        total: total,
      }"
      :loading="loading"
      @change="onChange"
    >
      <!-- <a href="#/" slot="keyword" slot-scope="text">{{text}}</a> -->
      <span slot="rang" slot-scope="text"
        >{{ text }} %<a-icon type="caret-up" />
      </span>
      <!-- <div slot="status" slot-scope="{ record }">
        <span v-if="record.status === 1" style="color:brown">在线</span>
        <span v-else style="color:red">离线</span>
      </div> -->
      <div slot="status" slot-scope="record">
        <span v-if="record === 1" style="color: green"> 在线</span>
        <span v-else style="color: red">离线</span>
        <!-- <span v-else style="color:red">故障</span> -->
      </div>
      <div slot="action" slot-scope="record">
        <a style="margin-right: 8px" @click="viewRecord(record)"> 监控 </a>
        <a style="margin-right: 8px" @click="viewRecord(record, true)">
          回放
        </a>
      </div>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "序列号",
    dataIndex: "imei",
    align: "center",
    width: "110px",
    // key: 'rank'
    customRender: (text, record) => {
      return `${record.imei}(${record.channel})`
    }
  },
  // {
  //   title: "通道号",
  //   dataIndex: "channel",
  //   width: "60px",
  //   align: "center",
  // },
  {
    title: "监控名称",
    dataIndex: "name",
    align: "center",
    ellipsis: true,
    // key: 'users',
    // sorter: (a, b) => a.count - b.count
  },
  {
    title: "状态",
    align: "center",
    dataIndex: "status",
    width: "50px",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    align: "center",
    width: "100px",
    scopedSlots: { customRender: "action" },
  },
];

import { dataSource as ds } from "@/services";
import { format } from "date-fns";
import ViewMonitor from "./ViewMonitor.vue";
export default {
  name: "MonitorTable",
  components: { ViewMonitor },
  data() {
    return {
      searchData: [],
      columns,
      loading: true,
      total: 0,
      current: 1,
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
      visibleMonitor: false,
      monitorId: "",
      recFlag: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "0",
    },
    pageSize: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    tableColumns() {
      let columns = this.columns;
      return columns.map((item) => {
        item.title = this.$t(item.key);
        return item;
      });
    },
  },
  methods: {
    update(id = this.id, stime = this.stime, etime = this.etime) {
      this.loading = true;
      console.log("LogTabel update");
      const { current, pageSize } = this;
      this.stime = stime;
      this.etime = etime;
      ds.getBindVideoList({
        deviceId: id,
        pageIndex: current,
        pageSize: pageSize,
      })
        .then((res) => {
          console.log("getBindVideoList", res);
          if (res.data.meta.success) {
            let { records, total } = res.data.data.list;
            // this.pageIndex = pageNum;
            this.total = total ?? 0;
            // this.pageSize = pageSize;
            records.map((v, index) => {
              v.key = index;
            });
            if (records.length > this.pageSize) {
              this.searchData = records.slice(0, this.pageSize);
            } else {
              this.searchData = records;
            }
            if (records.length > 0) {
              let firstOnline = records.find(elem => elem.status === 1);
              if(firstOnline) {
                this.$emit("change", firstOnline);
              } else {
                this.$emit("change", records[0]);
              }
            } else {
              this.$emit("change", null);
            }
            console.log("LogTabel searchData", this.searchData);
          }
        })
        .catch((err) => {
          console.log("LogTabel deviceInfoDayListErr", err);
          this.searchData = [];
          this.$emit("change", null);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("MonitorTable onChange", e);
      const { current } = e;
      this.current = current;
      this.update();
    },
    viewRecord(record, flag = false) {
      console.log("viewRecord", record, flag);
      this.recFlag = flag;
      this.monitorId = record.id;
      this.visibleMonitor = true;
    },
  },
};
</script>

<style lang="less" scoped>
.monitor_table {
  // padding-bottom: 10px;
  // height: 278px;
  background: rgba(0, 20, 74, 0.8);
  // border: 1px solid #027cc3;
  // box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  :deep(.ant-table) {
    min-height: 140px;
  }
  :deep(.ant-empty-normal) {
    background-color: #fff0;
    color: #00f8fe;
  }

  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    border-top-color: #fff2;
  }

  :deep(.ant-table-small) {
    // border: 1px solid #0169ad;
    border: none;
    .ant-table-thead {
      > tr {
        > th {
          color: #00f8fe;
          border-bottom: 1px solid #fff5;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        > td {
          color: #fff;
          border-bottom: 1px solid #fff2;
        }
        &:hover {
          > td {
            background-color: #000000aa;
          }
        }
      }
    }
  }

  :deep(.ant-table-wrapper) {
      .ant-spin-nested-loading {
        .ant-spin-container {
          height: 120px;
        }
      }
  }

  :deep(.ant-pagination) > {
    .ant-pagination-item a {
      color: #407fc6;
    }
  }
}
:deep(.ant-empty-normal) {
  min-height: 59px;
  .ant-empty-image {
    height: 25px;
  }
}
</style>
