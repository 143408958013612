var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    style: "height: ".concat(_vm.mcheight, "px;overflow:hidden;"),
    attrs: {
      "id": "bmap_div"
    }
  }, [_c('baidu-map', {
    staticClass: "bm-view",
    style: "height: ".concat(_vm.mcheight + 50, "px"),
    attrs: {
      "zoom": 8,
      "center": _vm.scenter,
      "inertial-dragging": ""
    },
    on: {
      "ready": _vm.mapReady
    }
  }, [_c('bm-navigation', {
    attrs: {
      "anchor": "BMAP_ANCHOR_TOP_RIGHT"
    }
  }), _vm._l(_vm.points, function (point, index) {
    return _c('bm-marker', {
      key: index,
      attrs: {
        "position": point
      },
      on: {
        "click": function click(e) {
          return _vm.pointClickHandler(e, point);
        }
      }
    });
  }), _c('bm-info-window', {
    attrs: {
      "position": _vm.infoPos,
      "show": _vm.infoWindowshow
    },
    on: {
      "close": _vm.infoWindowClose,
      "open": _vm.infoWindowOpen
    }
  }, [_vm.infoWindowshow ? _c('map-info-card', {
    key: _vm.key,
    attrs: {
      "devData": _vm.infoWindowdata,
      "spinning": _vm.infoWindowspinning,
      "ferTem": _vm.pushData ? _vm.pushData.ferTem : null,
      "envTem": _vm.pushData ? _vm.pushData.envTem : null,
      "envHum": _vm.pushData ? _vm.pushData.envHum : null,
      "title": "tit"
    },
    on: {
      "clickName": _vm.clickName
    }
  }) : _vm._e()], 1)], 2)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };