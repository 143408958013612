<template>
  <div class="analysis">
    <a-row style="margin-top: 0" :gutter="[24, 24]">
      <a-col
        :sm="24"
        :md="24"
        :xl="{ span: 7, push: 17 }"
        :xxl="{ span: 7, push: 17 }"
      >
        <div class="class_top_view">
          <div class="class_top_view_item">
            <map-view
              ref="mapview"
              :center="selectregion"
              :mcheight="920"
              @btnClick="bmapClick"
              @mapClick="bmapClick"
              @pointClick="pointClick"
            />
          </div>
        </div>
      </a-col>
      <a-col
        :sm="24"
        :md="24"
        :xl="{ span: 6, pull: 7 }"
        :xxl="{ span: 6, pull: 7 }"
      >
        <monitor-view :deviceList="deviceList" />
      </a-col>
      <a-col
        :sm="24"
        :md="24"
        :xl="{ span: 11, pull: 7 }"
        :xxl="{ span: 11, pull: 7 }"
      >
        <a-row :gutter="[16, 24]">
          <a-col :xs="14">
            <chart-card
              :loading="loading.deviceoverview"
              title="设备处理量"
              class="standard_card"
            >
              <span slot="action">
                <a-tooltip
                  title="刷新"
                  style="margin-right:10px;"
                  @click="deviceTotalUpdate"
                >
                  <a-icon type="reload" />
                </a-tooltip>
                <a-tooltip title="设备处理量" slot="action" @click="test">
                  <a-icon type="info-circle-o" />
                </a-tooltip>
              </span>
              <div>
                <div class="base_info">
                  <div class="base_info_bar">
                    <span class="title">处理量</span
                    ><span class="value">{{ input }} kg</span>
                  </div>
                  <mini-progress
                    percent="50"
                    color="#13C2C2"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                  <div class="base_info_bar">
                    <span class="title">产出物</span
                    ><span class="value">{{ output }} kg</span>
                  </div>
                  <mini-progress
                    :percent="(output / input) * 50"
                    color="#007bff"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                  <div class="base_info_bar">
                    <span class="title">减碳量</span
                    ><span class="value"
                      >{{ Math.floor(input * 1.47 * 10) / 10 }} kg</span
                    >
                  </div>
                  <mini-progress
                    :percent="50 * 1.47"
                    color="#1fc3af"
                    bkcolor="#1f3055"
                    height="10px"
                  />
                </div>
              </div>
            </chart-card>
          </a-col>
          <a-col :xs="10">
            <chart-card
              :loading="loading.totleDeviceStatus"
              title="设备运行状态"
              class="standard_card"
            >
              <span slot="action">
                <a-tooltip
                  title="刷新"
                  style="margin-right:10px;"
                  @click="totleDeviceStatusUpdate"
                >
                  <a-icon type="reload" />
                </a-tooltip>
                <a-tooltip title="设备运行情况">
                  <a-icon type="info-circle-o" />
                </a-tooltip>
              </span>
              <device-status ref="reftds" />
            </chart-card>
          </a-col>
        </a-row>
        <status-card
          :loading="loading.devicenum"
          class="standard_card_b"
        >
          <div slot="header" class="standard_card_b_header">
            <a-row style="margin-top: 0" :gutter="[24, 24]">
              <a-col
                :xs="24"
                :sm="10"
                :md="12"
                :xl="24"
                :xxl="10"
                class="standard_card_b_header_col_l"
              >
                <span class="chart-card-title">设备：</span>
                <a-select
                  style="width: 200px"
                  v-model="selectedDevice"
                  @change="handleChange"
                >
                  <a-select-option
                    v-for="(item, index) in deviceList"
                    :key="index"
                    :value="index"
                  >
                    {{ item.deviceSn }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col
                :xs="0"
                :sm="14"
                :md="12"
                :xl="0"
                :xxl="14"
                class="standard_card_b_header_col_r"
              >
                <span class="chart-card-title">时间：</span>
                <a-range-picker
                  :default-value="[
                    moment(this.stime.substr(0, 10), dateFormat),
                    moment(this.etime.substr(0, 10), dateFormat),
                  ]"
                  :ranges="{
                    今天: [moment(), moment()],
                    本周: [moment().startOf('week'), moment()],
                    本月: [moment().startOf('month'), moment()],
                    今年: [moment().startOf('year'), moment()],
                  }"
                  :format="dateFormat"
                  :title="`时间范围: ${stime} - ${etime}`"
                  @change="onChange"
                >
                  <span class="daterange_display">
                    {{ stime.split(" ")[0] }}<span>至</span
                    >{{ etime.split(" ")[0]
                    }}<span><a-icon type="calendar" theme="filled"/></span
                  ></span>
                </a-range-picker>
              </a-col>
              <!-- <a-tooltip title="规模数量">
                <a-icon type="info-circle-o" />
              </a-tooltip> -->
            </a-row>
          </div>
          <div class="standard_card_b_chart">
            <!-- <mini-area /> -->
            <!-- <status-card-info /> -->
            <daily-capacity ref="refdc" />
            <!-- <real-time-temp ref="refrt" title="实时数据" /> -->
            <h4 class="standard_card_b_chart_title">实时数据</h4>
            <temp-chart ref="refrt" :height="260" />
          </div>
        </status-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import StatusCard from "./chart/StatusCard";
import ChartCard from "../dashboard/chart/ChartCard";
import MiniProgress from "../dashboard/chart/MiniProgress";

import MapView from "./MapView";
import MonitorView from "./MonitorView.vue";
import DeviceStatus from "./chart/DeviceStatus.vue";
// import StatusCardInfo from "./chart/StatusCardInfo.vue";
import DailyCapacity from "./chart/DailyCapacity.vue";
import RealTimeTemp from "./chart/RealTimeTemp.vue";
import TempChart from "../../components/TempChart.vue";
import { dataSource as ds } from "@/services";
import { format } from "date-fns";
const beginDay = new Date().getTime();
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import websocket_plug from "@/plugins/websocket";

const rankList = [];

for (let i = 0; i < 8; i++) {
  rankList.push({
    name: "桃源村" + i + "号店",
    total: 1234.56 - i * 100,
  });
}

export default {
  name: "Analysis",
  data() {
    return {
      rankList,
      showmap: false,
      selectregion: "",
      tableCardStyle: {
        padding: 0,
      },
      loading: {
        deviceoverview: false,
        devicenum: false,
        totleDeviceStatus: false,
      },
      input: 100,
      output: 80,
      stime: format(
        new Date(beginDay - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(beginDay), "yyyy-MM-dd HH:mm:ss"),
      sstime: format(new Date("2000-01-01"), "yyyy-MM-dd HH:mm:ss"),
      eetime: format(new Date(beginDay), "yyyy-MM-dd HH:mm:ss"),
      dateFormat: "YYYY-MM-DD",
      deviceList: [],
      selectedDevice: null,
      time2: [],
      deviceSn: null,
    };
  },
  created() {
    console.log("stime", this.stime, this.etime);
  },
  mixins: [websocket_plug],
  components: {
    MiniProgress,
    StatusCard,
    ChartCard,
    MapView,
    DeviceStatus,
    DailyCapacity,
    TempChart,
    // eslint-disable-next-line vue/no-unused-components
    RealTimeTemp,
    MonitorView,
  },
  mounted() {
    this.totleDeviceStatusUpdate();
    this.deviceTotalUpdate();
    this.loadDeviceList();
  },
  activated() {
    console.log("userDashBoard activated");
    this.etime = format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");
    this.eetime = format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");
  },
  deactivated() {
    console.log("userDashBoard deactivated");
    this.ws_close();
    if (this.deviceSn) {
      this.setup_url(this.deviceSn, Date.now());
      this.ws_connect(() => {
        this.ws_bindMsg(this.getWsData);
      });
    }
  },
  beforeDestroy() {
    console.log("userDashBoard beforeDestroy");
    this.ws_close();
  },
  methods: {
    moment,
    loadDeviceList() {
      ds.deviceList({ pageIndex: 1, pageSize: 20 }).then((result) => {
        console.log("getDeviceList", result);
        if (result.data.meta.success) {
          console.log("dataSource", result.data.data.list);
          this.deviceList = result.data.data.list.list;
          // this.total = result.data.data.list.total
          if (this.deviceList.length !== 0) {
            this.$refs.mapview.loadData(this.deviceList);
            this.selectedDevice = 0;
            this.handleChange(this.selectedDevice);
            // this.selectDevice(this.deviceList[0].deviceSn);
          }
        } else {
          if (result.data.meta.code === 1006) {
            this.$message.error("获取设备列表出错：登录已过期，请重新登录");
            this.$router.push("/login");
          } else {
            this.$message.error("获取设备列表出错：" + result.data.meta.msg);
          }
          // this.$message.error(result.data.meta.msg);
        }
      });
    },
    deviceTotalUpdate() {
      console.log("deviceTotalUpdate");
      this.loading.deviceoverview = true;
      // setTimeout(() => (this.loading.deviceoverview = !this.loading.deviceoverview), 1000);
      // ds.deviceInOutData().then(res=>{
      //   console.log('deviceInOutData', res)
      //   // this.$refs.refdn.update(res.data.data.data)
      // }).catch(err=>{
      //   console.log('deviceInOutDataErr', err)
      // }).finally(()=>{
      //   this.loading.deviceoverview = false
      // })
      ds.deviceDayData({
        stime: this.stime,
        etime: this.etime,
      })
        .then((res) => {
          console.log("deviceDayData", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          const sinput = Number(((res.data.data.data?.in || 0) + (res.data.data.data?.pltWst || 0)).toFixed(2));
          this.input = sinput;
          this.output = res.data.data.data.out;
        })
        .catch((err) => {
          console.log("deviceDayDataErr", err);
        })
        .finally(() => {
          this.loading.deviceoverview = false;
        });
    },
    totleDeviceStatusUpdate() {
      console.log("totleDeviceStatusUpdate");
      this.loading.totleDeviceStatus = true;
      // setTimeout(() => (this.loading.totleDeviceStatus = !this.loading.totleDeviceStatus), 1000);
      ds.deviceStatusData()
        .then((res) => {
          console.log("deviceStatusData", res);
          if (res.data.meta.code !== 6666) throw res.data.meta.msg;
          this.$refs.reftds.update(res.data.data.data);
        })
        .catch((err) => {
          this.$refs.reftds.update();
          console.log("deviceStatusDataErr", err);
        })
        .finally(() => {
          this.loading.totleDeviceStatus = false;
        });
    },
    selectDevice(deviceSn) {
      this.deviceSn = deviceSn;
      this.ws_close();
      this.$refs.refdc.update(deviceSn, this.stime, this.etime);
      this.$refs.refrt.update(deviceSn, this.sstime, this.eetime);
      if (this.deviceSn) {
        this.setup_url(
          this.deviceSn,
          Date.now() * 100 + Math.floor(Math.random() * 100)
        );
        this.ws_connect(() => {
          this.ws_bindMsg(this.getWsData);
        });
      }
    },
    handleChange(value) {
      console.log(`selected :`, value);
      this.$refs.mapview.selectDevice(this.deviceList[value]);
      this.selectDevice(this.deviceList[value].deviceSn);
    },
    onChange(date, dateString) {
      console.log(date, dateString);
      let etime = format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss");
      let stime = format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      );
      if (date.length === 0) {
        this.etime = etime;
        // this.stime = stime;
        this.stime = stime.substr(0, 10) + " 00:00:00";
      } else {
        // this.stime = dateString[0] + stime.substr(10);
        this.stime = dateString[0] + " 00:00:00";
        this.etime = dateString[1] + stime.substr(10);
      }
      this.selectDevice(this.deviceSn);
    },
    mapClick(e) {
      console.log(e);
      this.selectregion = e.name;
      this.showmap = true;
    },
    getWsData(e) {
      // console.log("getWsData", e.data);
      this.$refs["refrt"].pushdata(e.data);
      this.$refs["mapview"].update(e.data);
    },
    test() {
      this.showmap = !this.showmap;
    },
    bmapClick(e) {
      console.log(e);
      // this.showmap = false;
      // this.$nextTick(() => {
      //   this.$refs.topview.chart.resize();
      // });
    },
    pointClick(e) {
      console.log("pointClick", e);
      let i = this.deviceList.findIndex(
        (d) => d.deviceId === e.device.deviceId
      );
      if (i >= 0) {
        this.selectedDevice = i;
        this.handleChange(i);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w200 {
  width: 270px;
}
.extra-wrap {
  .extra-item {
    display: inline-block;
    margin-right: 24px;
    a:not(:first-child) {
      margin-left: 24px;
    }
  }
}
@media screen and (max-width: 992px) {
  .extra-wrap .extra-item {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .extra-wrap {
    display: none;
  }
}
.class_top_view {
  position: relative;
  height: 920px;
  // background-color: #f0f2f5;
  &_item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
  }
}
.standard_card {
  height: 250px;
}
.standard_card_b {
  height: 650px;
  margin-top: 20px;
  &_header {
    margin: -12px 0 0;
    &_col_r {
      text-align: right;
      height: 32px;
      line-height: 32px;
      .daterange_display {
        border: 1px solid #0f4583;
        padding: 5px 12px;
        border-radius: 3px;
        span {
          color: #31a9f7;
        }
        span:nth-child(1) {
          margin: 0 5px;
        }
        span:last-child {
          margin-left: 5px;
        }
      }
    }
  }
  &_chart {
    &_title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00ecff;
      left: 10px;
    }
  }
  :deep(.ant-input) {
    background-color: #021548;
    border-color: #0f4583;
    color: #fff;
  }
  :deep(.ant-select-selection) {
    background-color: #021548;
    border-color: #0f4583;
    color: #fff;
  }
  :deep(.ant-select-selection__clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-cascader-picker-clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-calendar-picker-clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-calendar-picker-clear:hover) {
    background-color: #0000;
    color: #fff6;
  }
}
.base_info {
  margin-top: 30px;
  .base_info_bar {
    display: flex;
    width: 100%;
    height: 15px;
    margin-top: 20px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    .title {
      // color: #FFFFFF;
      color: #ffffff;
    }
    .value {
      margin-left: auto;
      color: #ffffff;
    }
  }
}
.fade-enter-active {
  transition: all 0.3s ease 0.3s;
}
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter, .fade-leave-to
/* .fade-leave-active for below version 2.1.8 */ {
  // transform: scale(0);
  filter: opacity(0);
  // opacity: 0;
}
</style>

<style lang="less">
.class_table_card {
  // background-color: #f0f2f5;
  // background-color: #f0f2f5;
  .ant-card-body {
    padding: 1px 1px 1px;
  }
}
</style>
