import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";

var DataSet = require("@antv/data-set");

var sourceData = [// { month: "Jan", Tokyo: 7.0, London: 3.9 },
  // { month: "Feb", Tokyo: 6.9, London: 4.2 },
  // { month: "Mar", Tokyo: 9.5, London: 5.7 },
  // { month: "Apr", Tokyo: 14.5, London: 8.5 },
  // { month: "May", Tokyo: 18.4, London: 11.9 },
  // { month: "Jun", Tokyo: 21.5, London: 15.2 },
  // { month: "Jul", Tokyo: 25.2, London: 17.0 },
  // { month: "Aug", Tokyo: 26.5, London: 16.6 },
  // { month: "Sep", Tokyo: 23.3, London: 14.2 },
  // { month: "Oct", Tokyo: 18.3, London: 10.3 },
  // { month: "Nov", Tokyo: 13.9, London: 6.6 },
  // { month: "Dec", Tokyo: 9.6, London: 4.8 },
];
var scale = [{
  dataKey: "month",
  min: 0,
  max: 10
}, {
  dataKey: "temperature",
  min: 0 // max: 100,

}];
import { dataSource as ds } from "@/services";
import { format } from "date-fns";
export default {
  name: "TempChart",
  props: ["title"],
  data: function data() {
    return {
      empty: true,
      emptyText: null,
      scale: scale,
      height: 260,
      label: {
        // offset: 8,
        textStyle: {
          fill: "#fff",
          fontSize: 12
        },
        formatter: function formatter(text) {
          return text.substr(5);
        }
      },
      sourceData: sourceData,
      style: {
        stroke: "#fff",
        lineWidth: 1
      },
      stime: format(new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24), "yyyy-MM-dd HH:mm:ss"),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss")
    };
  },
  computed: {
    vdata: function vdata() {
      var dv = new DataSet.View().source(this.sourceData);
      dv.transform({
        type: "fold",
        fields: ["环境温度", "发酵温度", "环境湿度"],
        key: "type",
        value: "temperature"
      });
      return dv.rows;
    }
  },
  methods: {
    update: function update() {
      var _this = this;

      var sn = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.sn;
      var stime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.stime;
      var etime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.etime;
      this.loading = false;
      return new Promise(function (resolve, reject) {
        ds.getDeviceInfos({
          deviceSn: sn,
          etime: etime,
          stime: stime,
          num: 6
        }).then(function (res) {
          if (res.data.meta.success) {
            var list = res.data.data.list.map(function (i) {
              // let time =  `${time.substr(0,10)} ${time.substr(11,8)}`;
              var time = i.createtime.substr(0, 10) + "_" + i.createtime.substr(11, 8);
              return {
                month: time,
                环境温度: +i.envTem,
                发酵温度: +i.ferTem,
                环境湿度: +i.envHum
              };
            });

            if (list.length > 0) {
              _this.sourceData = list;
              _this.empty = false;
            } else {
              _this.sourceData = [];
              _this.empty = true;
              _this.emptyText = null;
            }
          }

          _this.loading = false;
          resolve();
        }).catch(function (err) {
          _this.loading = false;
          _this.emptyText = '获取数据出错';
          _this.sourceData = [];
          reject();
          _this.empty = true;
        });
      });
    },
    pushdata: function pushdata(edata) {
      edata = JSON.parse(edata);
      if (edata.time === undefined) return;
      var time = format(new Date(new Date(edata.time)), "yyyy-MM-dd_HH:mm:ss");
      this.sourceData.push({
        month: time,
        环境温度: edata.envTem,
        发酵温度: edata.ferTem,
        环境湿度: edata.envHum
      });

      if (this.sourceData.length >= 6) {
        this.sourceData.shift();
      } // console.log(this.vdata)

    }
  }
};