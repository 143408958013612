var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    ref: "main_chart",
    staticClass: "chart",
    style: "height: ".concat(_vm.height, "px")
  })]);
};

var staticRenderFns = [];
export { render, staticRenderFns };