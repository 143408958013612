<template>
  <status-card :loading="loading" class="monitor_view">
    <div slot="header" class="monitor_view_header">
      <a-row style="margin-top: 0" :gutter="[24, 24]">
        <a-col :xs="24" class="monitor_view_header_col_l">
          <span class="chart-card-title">设备：</span>
          <a-select
            style="width: 340px"
            v-model="selectedDevice"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in deviceList"
              :key="index"
              :value="index"
            >
              {{ item.deviceSn }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
    </div>
    <div :key="videoKey" class="monitor_view_pad">
      <div id="bmv_video" class="container">
        <div>无视频或正在加载数据</div>
      </div>
    </div>
    <div class="monitor_table_title">
      监控信息
    </div>
    <monitor-table ref="mtable" :pageSize="9" @change="mtabelChange" />
  </status-card>
</template>

<script>
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
  toRef,
  watch,
  // onMounted,
  // onBeforeUnmount,
  // computed,
} from "vue";
// eslint-disable-next-line no-unused-vars
import store from "@/store/index";
// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
// eslint-disable-next-line no-unused-vars
import EZUIKit from "ezuikit-js";
import StatusCard from "./chart/StatusCard";
import ViewMonitor from "../../components/ViewMonitor.vue";
import MonitorTable from "../../components/MonitorTable.vue";

export default defineComponent({
  components: { StatusCard, ViewMonitor, MonitorTable },
  name: "MonitorView",
  props: {
    deviceList: {
      type: Array,
      default: () => [],
    },
  },
  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const loading = ref(false);
    const selectedDevice = ref(0);
    const mtable = ref();
    const deviceList = toRef(props, "deviceList");
    watch(deviceList, (newValue) => {
      console.log("deviceList watch", newValue, mtable);
      if (Array.isArray(newValue) && newValue.length > 0) {
        let id = newValue[0]?.deviceId;
        if (id) {
          nextTick(() => {
            console.log("deviceList watch nextTick");
            if (mtable.value.update) mtable.value.update(id);
          });
        }
      }
    });

    const handleChange = (e) => {
      console.log("handleChange", e, props.deviceList, mtable);
      if (Array.isArray(props.deviceList) && props.deviceList.length > 0) {
        let id = props.deviceList[e]?.deviceId;
        if (id) {
          nextTick(() => {
            if (mtable.value.update) mtable.value.update(id);
          });
        }
      }
    };

    let playr = reactive(null);
    const videoKey = ref(0);
    const startPlayer = (data, token) => {
      console.log("startPlayer 播放", playr);
      if(playr) {
        // 停止再播放
        console.log("startPlayer 停止再播放");
        playr.stop().then(() => {
          playr.play({url: data});
        })
      } else {
        playr = new EZUIKit.EZUIKitPlayer({
          id: "bmv_video", // 视频容器ID
          accessToken: token,
          url: data,
          template: "theme", //
          autoplay: true,
          width: 400,
          height: 310,
        });
      }
    };

    const mtabelChange = async (e) => {
      console.log("mtabelChange", e);
      if (e?.id) {
        let res = await ds.getVideoInfo(e.id);
        console.log("mtabelChange 加载视频", res);
        let token = await store.dispatch("token/getYStoken");
        let url = res.data.data.data?.url;
        if (url && token) {
          startPlayer(url, token);
        } else {
          console.log("mtabelChange 无法播放视频", url, token);
          if(playr !== null) {
            playr.stop().then(() => {
              videoKey.value = Date.now();
              playr = null;
            })
          }
        }
      } else {
        console.log("mtabelChange 无视频");
        if(playr !== null) {
          playr.stop().then(() => {
            videoKey.value = Date.now();
            playr = null;
          })
        }
      }
    };

    return {
      loading,
      selectedDevice,
      handleChange,
      mtable,
      mtabelChange,
      videoKey,
    };
  },
});
</script>


<style lang="less" scoped>
.monitor_view {
  height: 920px;
  &_header {
    margin: -12px 0 0;
    &_col_r {
      text-align: right;
      height: 32px;
      line-height: 32px;
      .daterange_display {
        border: 1px solid #0f4583;
        padding: 5px 12px;
        border-radius: 3px;
        span {
          color: #31a9f7;
        }
        span:nth-child(1) {
          margin: 0 5px;
        }
        span:last-child {
          margin-left: 5px;
        }
      }
    }
  }

  &_pad {
    border: 1px solid #002281;
    width: 400px;
    background: #00044a;
    position: relative;
    height: 311px;
    overflow: hidden;
    .container {
      position: relative;
      display: flex;
      height: 311px;
      top: -5px;
      justify-content: center;
      align-items: center;
      user-select: none;
    }
  }
  .monitor_table_title {
    padding: 5px;
  }

  :deep(.ant-input) {
    background-color: #021548;
    border-color: #0f4583;
    color: #fff;
  }
  :deep(.ant-select-selection) {
    background-color: #021548;
    border-color: #0f4583;
    color: #fff;
  }
  :deep(.ant-select-selection__clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-cascader-picker-clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-calendar-picker-clear) {
    background-color: #0000;
    color: #fff6;
  }
  :deep(.ant-calendar-picker-clear:hover) {
    background-color: #0000;
    color: #fff6;
  }
}
</style>