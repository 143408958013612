<template>
  <div class="optchart">
    <div class="title" @click="test">{{ title }}</div>
    <div class="content" :style="`height: ${height}px`">
      <div v-if="empty" class="chart_empty">暂无数据</div>
      <div
        ref="main_chart"
        class="chart"
        :style="`height: ${height}px`"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { debounceTail } from "@/utils/debounce";
import { dataSource as ds } from "@/services";
import { format } from "date-fns";

export default {
  name: "OptChart",
  props: ["sn", "id"],
  data() {
    return {
      empty: true,
      sourceData: [],
      option: null,
      myChart: null,
      resizeHandle: null,
      chartWidth: 0,
      title: "处理量",
      height: 250,
      stime: format(
        new Date(new Date().getTime() - 1000 * 30 * 60 * 60 * 24),
        "yyyy-MM-dd HH:mm:ss"
      ),
      etime: format(new Date(new Date().getTime()), "yyyy-MM-dd HH:mm:ss"),
    };
  },
  mounted() {
    this.resizeHandle = debounceTail(() => this.resize(), 299, this);
    window.addEventListener("resize", this.resizeHandle);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  methods: {
    resize() {
      if (this.myChart) {
        this.myChart.resize();
        let newWidth = this.myChart.getWidth();
        if (this.chartWidth !== newWidth) {
          this.myChart.clear();
          this.myChart.setOption(this.option);
          this.chartWidth = newWidth;
        }
      }
    },
    init_chart(v) {
      const colors = ["#5470C6", "#91CC75", "#EE6666"];
      if (!this.myChart) this.myChart = echarts.init(this.$refs.main_chart);
      this.option = {
        title: {
          text: this.title,
          show: false,
          textStyle: {
            color: "#fff",
          },
        },
        color: colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            // type: "shadow",
          },
        },
        grid: {
          bottom: "8%",
          left: "5%",
          right: "5%",
          top: "8%",
        },
        legend: {
          data: ["处理量(kg)", "产出物(kg)", "电量(度)"],
          show: false,
          right: "2%",
          textStyle: {
            color: "#fff",
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#fff",
              formatter: function(value) {
                return value.substr(5);
              },
            },
            // prettier-ignore
            data: v.map(i=>i.date)
          },
        ],
        yAxis: [
          {
            type: "value",
            // name: '处理量',
            position: "left",
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.2,
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: "{value}",
            },
          },
          // {
          //   type: 'value',
          //   name: '电量',
          //   position: 'right',
          //   splitLine: {
          //     show: false,
          //   },
          //   axisLine: {
          //     show: true,
          //     lineStyle: {
          //       color: colors[2]
          //     }
          //   },
          //   axisLabel: {
          //     formatter: '{value}'
          //   }
          // }
        ],
        series: [
          {
            name: "处理量(kg)",
            type: "bar",
            barWidth: 20,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 1, color: "#0c5eb2" },
              { offset: 0.5, color: "#0d6fbb" },
              { offset: 0, color: "#0faee1" },
            ]),
            data: v.map((i) => i.dlyIn),
          },
          // {
          //   name: '产出物',
          //   type: 'bar',
          //   barWidth: 20,
          //   data: v.map(i=>i.dlyOut)
          // },
          // {
          //   name: '电量',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   data: v.map(i=>i.dn)
          // }
        ],
      };
      // this.option.xAxis.data = v.map((i) => i.rank);
      // this.option.series[0].data = v.map((i) => i.num);
      this.myChart.setOption(this.option);
      this.chartWidth = this.myChart.getWidth();
    },
    update(sn = this.sn, stime = this.stime, etime = this.etime) {
      this.loading = false;
      console.log("optchart update");
      ds.deviceInfoDayList({
        deviceSn: sn,
        etime: etime,
        pageIndex: 1,
        pageSize: 6,
        stime: stime,
      })
        .then((res) => {
          console.log("optchart deviceInfoDayList", res);
          this.sourceData = [];
          this.empty = true;
          if (res.data.meta.success) {
            let list = res.data.data.list.list;
            if (list.length > 0) {
              list.map((v) => {
                v.date = v.date.replace("-", "年");
                v.date = v.date.replace("-", "月");
                v.date += "日";
              });
              list.map((v, index) => {
                v.key = index;
              });
              this.sourceData = list;
              this.empty = false;
            }
          }
          this.$nextTick(() => {
            this.init_chart(this.sourceData);
          });
        })
        .catch((err) => {
          console.log("optchart deviceInfoDayListErr", err);
          this.empty = true;
          this.sourceData = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    test() {
      // this.sourceData[0].sales+=10;
      this.update();
      // this.transformData();
      console.log("test", this.sourceData, this.vdata);
    },
  },
};
</script>

<style scoped lang="less">
.optchart {
  position: relative;
  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #00ecff;
    left: 10px;
  }
  .chart_empty {
    position: absolute;
    top: calc(50% - 22px);
    left: calc(50% - 39px);
    z-index: 10;
    border: 1px solid #017ac1;
    background: #011448;
    padding: 10px;
    font-size: 14px;
    border-radius: 3px;
  }
  :deep(.ant-empty-description) {
    color: #00f8fe;
  }
}
</style>
