var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('popup-form', {
    attrs: {
      "width": 860,
      "visible": _vm.visible,
      "title": null,
      "maskClosable": false,
      "footer": null
    },
    on: {
      "cancel": _vm.cancel,
      "ok": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "monitor_pad",
    attrs: {
      "id": "ysopen"
    }
  })]);
};

var staticRenderFns = [];
export { render, staticRenderFns };