import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find-index.js";
import MapInfoCard from "../../components/MapInfoCard.vue"; // eslint-disable-next-line no-unused-vars

import { dataSource as ds } from "@/services";
export default {
  name: "MapView",
  props: {
    center: {
      type: String,
      default: "广西"
    },
    mcheight: {
      type: Number,
      default: 640
    }
  },
  components: {
    MapInfoCard: MapInfoCard
  },
  data: function data() {
    return {
      loading: false,
      city: "",
      province: "",
      district: "",
      // 地图你解析方法实例
      myGeo: null,
      BMap: null,
      map: null,
      // 已选坐标，呈现mark用
      point: {
        lng: 116.404,
        lat: 39.915
      },
      // 搜索关键字
      keyword: "",
      slocal: "",
      street: "",
      place: "",
      key: 1,
      points: [],
      infoPos: {
        lng: 116.404,
        lat: 39.915
      },
      infoWindowshow: false,
      infoWindowspinning: false,
      infoWindowdata: {},
      pushData: null
    };
  },
  computed: {
    scenter: function scenter() {
      return this.center ? this.center : "广西";
    },
    spoint: function spoint() {
      return "[ ".concat(this.point.lng, ", ").concat(this.point.lat, " ]");
    }
  },
  methods: {
    mapReady: function mapReady(_ref) {
      var BMap = _ref.BMap,
          map = _ref.map;
      // 获取逆解析方法实例
      this.myGeo = new BMap.Geocoder();
      this.myGeo = new BMap.Geocoder();
      this.BMap = BMap;
      this.map = map;
    },
    mapClick: function mapClick(e) {
      this.$emit("mapClick", e);
    },
    btnClick: function btnClick(e) {
      this.$emit("btnClick", e);
    },
    pointClickHandler: function pointClickHandler(e, p) {
      this.infoPos = e.point;
      this.map.setCenter(e.point);
      this.key = Date.now();
      this.infoWindowshow = true;
      this.infoWindowdata = p.device;
      this.$emit("pointClick", p);
    },
    infoWindowClose: function infoWindowClose() {
      this.infoWindowshow = false;
    },
    infoWindowOpen: function infoWindowOpen() {
      var _this = this;

      this.infoWindowshow = true;
      this.infoWindowspinning = true;
      setTimeout(function () {
        _this.infoWindowspinning = false;
      }, 200);
    },
    clear: function clear() {},
    setZoom: function setZoom(bPoints) {
      var view = this.map.getViewport(eval(bPoints));
      var mapZoom = view.zoom;
      var centerPoint = view.center;
      this.map.centerAndZoom(centerPoint, mapZoom);
    },
    loadData: function loadData(devicelist) {
      var _this2 = this;

      var points = devicelist.map(function (p) {
        return {
          lng: p.lon,
          lat: p.lat,
          device: p
        };
      });
      this.points = points;
      setTimeout(function () {
        _this2.setZoom(_this2.points);
      }, 377);
    },
    update: function update(edata) {
      edata = JSON.parse(edata);
      this.pushData = edata;
    },
    selectDevice: function selectDevice(device) {
      var i = this.points.findIndex(function (d) {
        return d.device.deviceId === device.deviceId;
      });

      if (i >= 0) {
        if (this.BMap !== null) {
          var point = new this.BMap.Point(parseFloat(this.points[i].lng), parseFloat(this.points[i].lat));
          this.map.setCenter(point);
          this.infoPos = point;
          this.infoWindowshow = true;
          this.infoWindowdata = this.points[i].device;
          this.pushData = null;
        }
      }
    },
    clickName: function clickName(e) {
      this.$router.push("/devices/detail?sn=".concat(e.deviceSn, "&id=").concat(e.deviceId));
    }
  }
};