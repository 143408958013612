var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-card', {
    staticClass: "chart-card",
    attrs: {
      "loading": _vm.loading,
      "body-style": {
        padding: '20px 24px 8px'
      },
      "bordered": false
    }
  }, [_c('div', {
    staticClass: "chart-card-header"
  }, [_vm._t("header")], 2), _c('div', {
    staticClass: "chart-card-content"
  }, [_c('div', {
    staticClass: "content-fix"
  }, [_vm._t("default")], 2)])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };